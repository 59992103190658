import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginForm from "./components/forms/LoginForm";
import CalenderBox from "./components/calender/Calender";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { UserContext } from "./utils/UserContext";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Loader from "./components/Loader/Loader";
import Projects from "./components/Project/Projects";
import Contact from "./components/contact/Contact";

import "./App.css";
import { createUseStyles } from "react-jss";

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={user ? <CalenderBox /> : <div className={classes.body}><LoginForm /></div>} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

const useStyles = createUseStyles({
  body: {
    height: "100vh",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    padding: "2rem",
  }
})

export default App;