import { useState, useContext } from "react";
import {
  collection,
  addDoc,
  getDoc,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { UserContext } from "../../utils/UserContext";
import firestore from "../../utils/Firebase";
import { createUseStyles } from "react-jss";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
  },
  label: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
  },
  input: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "10px",
    width: "100%",
  },
  button: {
    padding: "10px",
    borderRadius: "5px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "#0069d9",
    },
  },
  timeSlot: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  checkbox: {
    marginRight: "10px",
  },
});

function Projects() {
  const user = useContext(UserContext);
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [role, setRole] = useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  //console.log(user)

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, ""]);
  };

  const handleRemoveTimeSlot = (index) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots.splice(index, 1);
    setTimeSlots(newTimeSlots);
  };

  const handleTimeSlotChange = (index, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index] = value;
    setTimeSlots(newTimeSlots);
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const projectRef = doc(collection(firestore, "projects"));
    await setDoc(projectRef, {
      name: projectName,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    });
    const projectId = projectRef.id;
    await setDoc(projectRef, { pid: projectId }, { merge: true });

    const date = new Date(startDate);
    while (date <= new Date(endDate)) {
      const dateString = date.toISOString().slice(0, 10);
      const timeSlotsRef = collection(projectRef, "timeSlots");
      for (const timeSlot of timeSlots) {
        const docRef = await addDoc(timeSlotsRef, {
          date: dateString,
          time: timeSlot,
          booked: false,
        });
        const sid = docRef.id;
        await updateDoc(docRef, { sid });
      }
      date.setDate(date.getDate() + 1);
    }

    window.alert("Project created successfully");
    navigate("/");
  } catch (e) {
    console.error("Error creating project: ", e);
  }
};

  //console.log(user.uid);
  const userID = user.uid;
  const getRole = async (user) => {
    onSnapshot(doc(firestore, "users", userID), (querySnapshot) => {
      const data = querySnapshot.data();
      //console.log(data)
      setRole(data.role);
    });
    //console.log(role);
  };

  useEffect(() => {
    getRole(user);
  }, [user]);

  return (
    <div className={classes.container}>
      <h2>Create Project</h2>
      <form className={classes.form} onSubmit={handleSubmit}>
        <label className={classes.label}>
          Project Name:
          <input
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            className={classes.input}
          />
        </label>
        <label className={classes.label}>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className={classes.input}
          />
        </label>
        <label className={classes.label}>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className={classes.input}
          />
        </label>
        <label className={classes.label}>Time Slots:</label>
        {timeSlots.map((timeSlot, index) => (
          <div key={index} className={classes.timeSlot}>
            <input
              type="checkbox"
              checked={!!timeSlot}
              onChange={(e) => handleTimeSlotChange(index, e.target.checked)}
              className={classes.checkbox}
            />
            <input
              type="time"
              value={timeSlot}
              onChange={(e) => handleTimeSlotChange(index, e.target.value)}
              disabled={!timeSlot}
              className={classes.input}
            />
            <button
              type="button"
              onClick={() => handleRemoveTimeSlot(index)}
              className={classes.button}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddTimeSlot}
          className={classes.button}
        >
          Add Time Slot
        </button>
        <br />
        <button type="submit" className={classes.button}>
          Save
        </button>
      </form>
    </div>
  );
}

export default Projects;
