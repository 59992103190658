import React, { createContext, useState, useEffect } from "react";
import { getAuth } from "firebase/auth";

import firestore from "./Firebase";
import { doc, setDoc, getDoc, getDocs } from "firebase/firestore";
import { Await } from "react-router-dom";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });
    return unsubscribe;
  }, [auth]);

  // store user in database if they don't exist	
  const createUser = async (user) => {
    const userRef = doc(firestore, "users", user.uid);
    const docSnap = await getDoc(userRef);
    if (!docSnap.exists()) {
      await setDoc(userRef, {
        displayName: user.displayName,
        email: user.email,
        uid: user.uid,
        role: "user",
      });
    }
   
    const role = await getDoc(firestore, "users", user.uid).role;
    const userRole = role.toString();
    setUser({
      displayName: user.displayName,
      email: user.email,
      uid: user.uid,
      role: userRole,
    })
  }
  

  useEffect(() => {
    if (user) {
      createUser(user);
    }
  }, [user]);
  

  //log out after 1 hour
  useEffect(() => {
    if (user) {
      const timeout = setTimeout(() => {
        auth.signOut();
      }, 3600000);
      return () => clearTimeout(timeout);
    }
  }
  , [user, auth]);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
