import { React, useState, useCallback, useEffect, useContext } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import firestore from "../../utils/Firebase";
import {
  collection,
  getDocs,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { UserContext } from "../../utils/UserContext";
import { Calendar, Day, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { createUseStyles } from "react-jss";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalenderBox = () => {
  const classes = useStyles();
  const [person, setPerson] = useState(null);
  const [time, setTime] = useState(null);
  const [day, setDay] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [date, setDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const user = useContext(UserContext);
  const getEvents = async () => {
    onSnapshot(collection(firestore, "bookings"), (querySnapshot) => {
      const events = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        start: doc.data().start.toDate(),
        title: doc.data().title,
        id: doc.id,
      }));
      setEvents(events);
    });
  };

  const getProjects = async () => {
    onSnapshot(collection(firestore, "projects"), (querySnapshot) => {
      const projects = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        startDate: doc.data().startDate.toDate(),
        title: doc.data().name,
        timeSlots: doc.data().timeSlots,
        endDate: doc.data().endDate.toDate(),
      }));
      setProjects(projects);

    });
  };

 

  useEffect(() => {
    getEvents();
    getProjects();
  }, []);

  function handleConfirm() {
    addBooking();
  }

  function handlePersonChange(e) {
    setPerson(e.target.value);
  }

  function handleTimeChange(e) {
    setTime(e.target.value);
  }

  function handleDayChange(e) {
    setDay(e.target.value);
  }

  function handleProjectChange(e) {
    const selectedProject = e.target.value;
    setProject(selectedProject);

    // Fetch the project document from the database using the Firebase SDK
    const projectsRef = collection(firestore, "projects");
    const q = query(projectsRef, where("name", "==", selectedProject));
    getDocs(q)
      .then((querySnapshot) => {
        // Retrieve the document ID from the query snapshot
        const projectId = querySnapshot.docs[0].id;
        // Retrieve the time slots from a new query using the document ID
        const timeSlotsRef = collection(
          firestore,
          "projects",
          projectId,
          "timeSlots"
        );
        return getDocs(timeSlotsRef);
      })
      .then((querySnapshot) => {
        // Retrieve the time slots from the query snapshot
        const timeSlots = querySnapshot.docs.map((doc) => doc.data());
        // Update the state with the time slots
        setTimeSlots(timeSlots);
      })
      .catch((error) => console.error(error));
  }

  const handleConflict = async (day, time) => {
    const date = new Date(day + " " + time);
    const now = new Date();
    const dayOfWeek = date.getDay();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
    const isPast = date < now;
    const isConflict = events.some((event) => {
      const start = new Date(event.start);
      const end = new Date(event.end);
      return (
        (date >= start && date < end) ||
        (date.getTime() + 30 * 60 * 1000 > start.getTime() && date < end)
      );
    });
    if (isWeekend || isPast || isConflict) {
      confirmAlert({
        title: "Error",
        message:
          "There is a conflict with the booking or the booking is on a weekend or in the past.",
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
      console.log("conflict");
      return true;
    }
    console.log("no conflict");
    return false;
  };

  const submit = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Booking for Project: " + "" + project + " at " + time + "?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleConfirm(),
        },
        {
          label: "No",
          onClick: () => setConfirmed(false),
        },
      ],
    });
  };

  const addBooking = () => {
    try {
      addDoc(collection(firestore, "bookings"), {
        title: project,
        start: new Date(time),
        end: new Date(time).setMinutes(new Date(time).getMinutes() + 30),
        userid: user.uid,
      });
      setConfirmed(true);
      //set the time slot to booked in the project
      const projectsRef = collection(firestore, "projects");
      const q = query(projectsRef, where("name", "==", project));
      getDocs(q)
        .then((querySnapshot) => {
          // Retrieve the document ID from the query snapshot
          const projectId = querySnapshot.docs[0].id;
          // Retrieve the time slots from a new query using the document ID
          const timeSlotsRef = collection(
            firestore,
            "projects",
            projectId,
            "timeSlots"
          );
          return getDocs(timeSlotsRef);
        })
        .then((querySnapshot) => {
          // Retrieve the time slots from the query snapshot
          const timeSlots = querySnapshot.docs.map((doc) => doc.data());
          // Update the state with the time slots
          setTimeSlots(timeSlots);
          // Retrieve the project ID again
          const projectId = querySnapshot.docs[0].ref.parent.parent.id;
          const timeSlotID = querySnapshot.docs[0].id;
          const timeSlotRef = doc(
            firestore,
            "projects",
            projectId,
            "timeSlots",
            timeSlotID
          );
          updateDoc(timeSlotRef, {
            booked: true,
          });
        })
        .catch((error) => console.error(error));
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div className={classes.calender}>
      <div className={classes.person}>
        <h2>Select a project:</h2>
        <select className={classes.button} onChange={handleProjectChange}>
          <option>Project</option>
          {projects.map((project) => (
            <option key={project.title} value={project.title}>
              {project.title}
            </option>
          ))}
        </select>
        <h2>Select a time slot:</h2>
        <select className={classes.button} onChange={handleTimeChange}>
          <option key="default">Time slot</option>
          {timeSlots.map(
            (slot) =>
              !slot.booked && (
                <option id={slot.id} key={slot.id} value={slot.id}>
                  {slot.date} {slot.time}
                </option>
              )
          )}
        </select>
        <button className={classes.button} onClick={submit}>Book</button>
      </div>
      <DnDCalendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="week"
        views={["week"]}
        timeslots={1}
        events={events}
        dra
        min={moment().hours(9).minutes(0).toDate()}
        max={moment().hours(18).minutes(0).toDate()}
        style={{ height: "70vh", width: "50vw" }}
      />
    </div>
  );
};

const useStyles = createUseStyles({
  calender: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgrey",
    width: "100vw",
    height: "100vh",
    "@media (max-width: 700px)": {
      flexDirection: "column",
      paddingTop: "25vh",
      width: "100vw",
      height: "100%",
    },
  },
  person: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "15vw",
    height: "50vh",
    marginBottom: "5vh",
    marginRight: "5vw",
    backgroundColor: "white",
    paddingBottom: "3vh",
    borderRadius: "8px",
    border: "5px solid rgb(252,159,49)",
    "@media (max-width: 700px)": {
      width: "30%",
      marginTop: "2vh",
      marginLeft: "0",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      paddingBottom: "1vh",
      "& p": {
        paddingTop: "1vh",
        paddingBottom: "0",
        margin: "0",
      },
    },
  },
  button: {
      width: "8vw",
      height: "4vh",
      fontWeight: "bold",
      fontSize: "1.5vh",
      backgroundColor: "rgb(252,159,49)",
      color: "white",
      border: "none",
      borderRadius: "5px",
      margin: "0.5rem",
      "&:hover": {
        color: "black",
        transition: "0.3s",
      },
    "& input": {
      width: "8vw",
      height: "4vh",
      fontWeight: "bold",
      fontSize: "1.5vh",
      backgroundColor: "rgb(252,159,49)",
      color: "white",
      border: "none",
      borderRadius: "5px",
      margin: "0.5rem",
    },
    "& select": {
      width: "8vw",
      height: "4vh",
      fontWeight: "bold",
      fontSize: "1.5vh",
      backgroundColor: "rgb(252,159,49)",
      color: "white",
      border: "none",
      borderRadius: "5px",
      margin: "0.5rem",
    },
    h2: {
      textAlign: "center",
      backgroundColor: "rgb(252,159,49)",
      color: "white",
      width: "50vw",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
      height: "10vh",
      borderRadius: "10px",
      fontSize: "2.5rem",
      fontWeight: "bold",
      margin: "0.5rem",
    },
  },
});

export default CalenderBox;
