import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  contact: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    height: "100vh",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "2rem",
  },
  heading: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  info: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem",
  },
});

function Contact() {
  const classes = useStyles();

  return (
    <div className={classes.contact}>
      <h1 className={classes.heading}>Contact Us:</h1>
        <p className={classes.info}>Hours: 9am-5pm</p>
        <p className={classes.info}>Monday-Friday</p>
      <p className={classes.info}>Phone: +64 (9) 359 2020</p>
      <p className={classes.info}>Email: jakec@onepicture.com</p>
      <p className={classes.info}>Address: 72 St Georges Bay Road Parnell, Auckland 1052</p>
    </div>
  );
}

export default Contact;