// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: process.env.APIKEY,
//   authDomain: process.env.AUTHDOMAIN,
//   projectId: process.env.PROJECTID,
//   storageBucket: process.env.STORAGEBUCKET,
//   messagingSenderId: process.env.MESSAGINGSENDERID,
//   appId: process.env.APPID,
//   measurementId: process.env.MEASUREMENTID
// };

const firebaseConfig = {
  apiKey: "AIzaSyACz68T7zze8pbwCRHSBxtbv1gHhTbFVnE",
  authDomain: 'onepic-booking-app.firebaseapp.com',
  projectId: 'onepic-booking-app',
  storageBucket: 'onepic-booking-app.appspot.com',
  messagingSenderId: '22724640493',
  appId: '1:22724640493:web:7d0225c4f459137ef356e4',
  measurementId: 'G-ECH87F89ZT'
};


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);

export default firestore;
