import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword, signInWithRedirect } from "firebase/auth";
import firestore from "../../utils/Firebase";
import { collection, getDocs, onSnapshot, addDoc, doc, setDoc } from "firebase/firestore";

import { UserContext } from "../../utils/UserContext";
import provider from "../../utils/AuthGoogleProvider";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    maxWidth: "500px",
    margin: "auto",
    paddingTop: "20vh",
    height: "100%",
    "& > form": {
      border: "5px solid #ccc",
      padding: "3rem",
    }
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px",
    width: "100%",
  },
  inputLabel: {
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "1rem",
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "1rem",
  },
  button: {
    padding: "5px",
    fontSize: "1rem",
    background: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      background: "#3e8e41",
    },
  },
});

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = getAuth();
  const classes = useStyles();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSignInWithEmail = (event, setUser) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSignInWithGoogle = (setUser) => {
    signInWithRedirect(auth, provider)
      .then((result) => {
        const user = result.user;
        setUser(user);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };


  

  return (
    <UserContext.Consumer>
      
      {(user, setUser) => (
        <div className={classes.formContainer}>
          <div>
            <h1>Sign In</h1>
            <p> Please use the login credentials that were emailed to you when you were informed of your eligibility for this research.</p>
            
          </div>
          <form onSubmit={(event) => handleSignInWithEmail(event, setUser)}>
            <div className={classes.inputContainer}>
              <label htmlFor="email" className={classes.inputLabel}>
                Email:
              </label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                id="email"
                className={classes.input}
              />
            </div>
            <div className={classes.inputContainer}>
              <label htmlFor="password" className={classes.inputLabel}>
                Password:
              </label>
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                id="password"
                className={classes.input}
              />
            </div>
            <div className={classes.buttonContainer}>
              <button type="submit" className={classes.button}>
                Sign In
              </button>
              {/* <button
                type="button"
                className={classes.button}
                onClick={() => handleSignInWithGoogle(setUser)}
              >
                Sign In With Google
              </button> */}
            </div>
          </form>
          <p> If you did not receive your credentials please contact us at jakec@onepicture.com</p>
        </div>
      )}
    </UserContext.Consumer>
  );
};


export default LoginForm;