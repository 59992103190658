import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  },
  spinner: {
    animation: "$spin 1s linear infinite",
    border: "5px solid #ccc",
    borderTopColor: "#333",
    borderRadius: "50%",
    height: "50px",
    width: "50px",
  },
  "@keyframes spin": {
    to: {
      transform: "rotate(360deg)",
    },
  },
});

const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <div className={classes.spinner} />
    </div>
  );
};

export default Loader;
