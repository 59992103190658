import React, { useState, useEffect, useContext } from "react";
import { createUseStyles } from "react-jss";
import { collection, addDoc, getDoc, onSnapshot, doc } from "firebase/firestore";
import { UserContext } from "../../utils/UserContext";
import firestore from "../../utils/Firebase";
import { getAuth, signOut } from "firebase/auth";

function rotateHamburger() {
  var bar1 = document.getElementById("bar1");
  var bar2 = document.getElementById("bar2");
  var bar3 = document.getElementById("bar3");

  if (bar1.style.transform === "translateY(11.5px) rotate(45deg)") {
    bar1.style.transform = "translateY(0px) rotate(0deg)";
    bar2.style.transform = "translateY(0px) rotate(0deg)";
    bar2.style.opacity = "1";
    bar3.style.transform = "translateY(0px) rotate(0deg)";
  } else {
    bar1.style.transform = "translateY(11.5px) rotate(45deg)";
    bar2.style.transform = "translateY(0px) rotate(0deg)";
    bar2.style.opacity = "0";
    bar3.style.transform = "translateY(-10px) rotate(-45deg)";
  }

  var dropdown = document.getElementById("dropdown");
  if (dropdown.style.top === "0vh") {
    dropdown.style.top = "-113vh";
  } else {
    dropdown.style.top = "0vh";
  }
}

function Header () {
  const classes = useStyles();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const user = useContext(UserContext);
  const [role, setRole] = useState("");
  const auth = getAuth();
  console.log(user)

  const userID = user ? user.uid : null;
  const getRole = async (user) => {
    if (userID) {
      onSnapshot(doc(firestore, "users", userID), (querySnapshot) => {
        const data = querySnapshot.data();
        //console.log(data)
        setRole(data.role);
      });
    }
  }

  useEffect(() => {
    getRole(user);
  }, [user]);

  // set dropdown options based on user role
  useEffect(() => {
    if (role === "admin") {
      setDropdownOptions([
        { label: "Home", href: "/" },
        { label: "Projects", href: "/projects" },
        { label: "Contact Us", href: "/contact" },
        { label: "Log Out", onClick: handleLogout },
      ]);
    } else {
      setDropdownOptions([
        { label: "Contact Us", href: "/contact" },
        { label: "Log Out", onClick: handleLogout },
      ]);
    }
  }, [role]);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.header} id="header">
      <a href="/">
        <img src="/img/onepiclogo.png" alt="logo" className={classes.logo} />
      </a>
      <div className={classes.hamburger} onClick={rotateHamburger}>
        <div className={classes.line} id="bar1"></div>
        <div className={classes.line} id="bar2"></div>
        <div className={classes.line} id="bar3"></div>
      </div>
      <div id="dropdown" className={classes.dropdown}>
        {dropdownOptions.map((option) => (
          <a key={option.label} href={option.href} onClick={option.onClick}>
            {option.label}
          </a>
        ))}
      </div>
    </div>
  );
};

const useStyles = createUseStyles({
  header: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    height: "13vh",
    "margin-top": "0px",
    position: "fixed",
    width: "100vw",
    zIndex: "2",
    "& > h1": {
      margin: "0px",
    },
    display: "flex",
    "align-items": "center",
  },
  logo: {
    height: "10vh",
    marginLeft: "3vw",
  },
  hamburger: {
    "flex-direction": "column",
    "justify-content": "space-between",
    height: "30px",
    width: "30px",
    zIndex: "1",
    position: "fixed",
    right: "3%",
    cursor: "pointer",
    "& > div": {
      height: "3px",
      width: "100%",
      background: "black",
      transition: "all 0.5s ease",
    },
  },
  line: {
    height: "4px",
    width: "100%",
    background: "black",
    marginBottom: "8px",
  },
  dropdown: {
    position: "fixed",
    right: "0vw",
    top: "-113vh",
    paddingTop: "13vh",
    height: "100vh",
    width: "100vw",
    background: "rgba(255, 255, 255, 1)",
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
    transition: "all 1s ease",
    "& > a": {
      padding: "1.5rem",
      margin: "3rem",
      color: "black",
      textDecoration: "none",
      fontSize: "1.5rem",
      borderRadius: "0.9rem",
    },
    "& > a:hover": {
      background: "rgba(0, 0, 0, 0.1)",
      fontWeight: "bold",
      transition: "0.2s",
    },
  },
});

export default Header;