import react from "react";
import { createUseStyles } from "react-jss";

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>

      <div className={classes.footerLegal}>
        <p className={classes.copyright}>
          One Picture &copy; 2023 All rights reserved{" "}
        </p>
      </div>
    </div>
  );
};

//use styles for footer displayed in columns and dont overlap body
const useStyles = createUseStyles({
  footer: {
    height: "10vh",
    backgroundColor: "black",
    color: "white",
    width: "100vw",
    bottom: "0",
    position: "fixed",
    "align-items": "center",
    "border-top": "1px solid #e6e6e6",
    "& a": {
      textDecoration: "none",
      fontWeight: "none",
      color: "white",
    },
    "& a:hover": {
      fontWeight: "bold",
      color: "rgb(252,159,49)",
      transition: "0.5s",
    },
  },
  footerContent: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    
  },
  nav: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    "list-style": "none",
    "text-align": "center",
    "margin-right": "2vw",
    "margin-left": "2vw",
    "font-weight": "bold",
    "font-size": "1.2rem",
    "& li": {
      "margin-bottom": "1rem",
    },
  },
  nav2: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    "list-style": "none",
    "text-align": "center",
    "margin-right": "2vw",
    "margin-left": "2vw",
    "font-weight": "bold",
    "font-size": "1.2rem",
    "& li": {
      "margin-bottom": "1rem",
    },
  },
  detail: {
    "& span": {
      "font-weight": "bold",
      "font-size": "1.2rem",
    },
    "& p": {
      "font-size": "1rem",
      "margin-top": "0",
      "margin-bottom": "0",
    },
  },
  telPhone: {
    "font-size": "1rem",
    "margin-top": "0",
    "margin-bottom": "0",
  },
  footerLegal: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "margin-top": "0.5rem",
    "margin-bottom": "0.5rem",
  },
});

export default Footer;
